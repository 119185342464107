<template>
  <div class="subpage">
    <FrameMD
      :posY="posY"
      :transitionPhoto="false"
      :staticPhoto="true"
      :photo="pageContent.section0.photo"
      :photoHeight="'500px'"
      :header="pageContent.section0.header"
      :text="pageContent.section0.text"
    ></FrameMD>
    
    <div class="container">
      <FrameBook
        v-for="(book, index) in pageContent.section1" :key="index"
        :posY="posY"
        :transitionPhoto="true"
        :photo="book.photo"
        :header="book.header"
        :text="book.text"
        :reversed="index % 2 == 0 ? false : true"
        :style="{marginBottom: '40px'}"
      ></FrameBook>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import FrameMD from '@/components/FrameMD'
import FrameBook from '@/components/FrameBook'

export default {
  name: 'Books',
  props: ['posY'],
  components: {FrameMD, FrameBook},
  data () {
      return {

      }
  },
  computed: {
    ...mapState({pageContent: state => state.data.books})
  }
}

</script>

<style lang="scss" scoped>

.container {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 100px auto;
}

</style>